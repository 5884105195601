.top {
  background-color: #242424;

  img {
    width: 15%;
    height: 30%;
    object-fit: cover;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 600px) {
  }

  h3 {
    margin-top: 26px;
    margin-left: 20px;
  }
  span {
    font-size: 12px;
    margin-left: 10px;
  }
}

.bo {
  background-color: #242424;

  .left {
    background-color: #fff;
  }
}
